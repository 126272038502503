<template>
  <div class="container">
    <md-card class="dash-content">
      <div class="header-wrapper">
        <PageHeader title="Manage Real Estate" />
        <div class="loader">
          <Wave
            v-if="loading || updating || deleting"
            width="50px"
            color="#d9534f"
          />
        </div>
        <div>
          <md-button class="md-primary" :to="{ name: 'Estate Create' }">
            Add New Property
          </md-button>
        </div>
      </div>
      <md-table
        v-model="properties.mdData"
        md-card
        md-sort="title"
        md-sort-order="asc"
      >
        <md-table-toolbar class="mb-4">
          <div class="md-toolbar-section-start">
            <md-button
              class="md-primary button-icon"
              :disabled="exporting"
              @click="exportExcel"
            >
              Download Excel
              <img src="@/assets/svg/excel.svg" />
            </md-button>
          </div>
          <div class="toolbar-filter-wrapper">
            <md-field md-clearable class="md-toolbar-section-end">
              <md-input placeholder="Search by name..." v-model="search" />
            </md-field>
            <md-menu md-size="medium" md-align-trigger>
              <md-button
                md-menu-trigger
                class="md-primary button-icon"
                disabled
              >
                Filter
                <img src="@/assets/svg/filter.svg" />
              </md-button>

              <md-menu-content>
                <md-menu-item>Approved</md-menu-item>
                <md-menu-item>Pending</md-menu-item>
              </md-menu-content>
            </md-menu>
          </div>
        </md-table-toolbar>
        <md-table-row
          slot="md-table-row"
          slot-scope="{ item }"
          :class="{ 'table-highlight': item.promote_count > 0 }"
        >
          <md-table-cell md-label="S/N" md-numeric>{{ item.id }}</md-table-cell>
          <md-table-cell md-label="Title">{{ item.title }}</md-table-cell>
          <md-table-cell md-label="State">{{
            item.state ? item.state.title : "--"
          }}</md-table-cell>
          <md-table-cell md-label="Location">{{ item.location }}</md-table-cell>
          <md-table-cell md-label="Agent">{{
            item.company_name
          }}</md-table-cell>
          <!-- <md-table-cell md-label="code">{{
            item.tracking_code
          }}</md-table-cell> -->
          <md-table-cell md-label="Status">
            <div
              class="badge"
              :class="{
                success: item.approval_status == 'approved',
                danger: item.approval_status == 'disapproved',
              }"
            >
              {{ item.approval_status }}
            </div></md-table-cell
          >
          <md-table-cell md-label="Actions">
            <md-menu md-size="medium" md-align-trigger>
              <md-button :disabled="updating" md-menu-trigger class="bggreen"
                >Menu</md-button
              >

              <md-menu-content>
                <md-menu-item
                  v-if="item.promote_count == 0"
                  @click="promote(item)"
                  >Promote <i class="material-icons">arrow_upward</i>
                </md-menu-item>
                <md-menu-item v-else @click="promote(item, 'update')"
                  >Update Promotion
                </md-menu-item>
                <md-menu-item @click="setProperty(item)">Preview</md-menu-item>
                <md-menu-item
                  v-if="
                    ['disapproved', 'pending'].includes(item.approval_status)
                  "
                  @click="changeStatus(item.id, 'approved')"
                  >Approve</md-menu-item
                >
                <md-menu-item
                  v-if="['approved', 'pending'].includes(item.approval_status)"
                  @click="changeStatus(item.id, 'disapproved')"
                  >Disapprove</md-menu-item
                >
                <md-menu-item>Edit </md-menu-item>
                <md-menu-item>Delete </md-menu-item>
              </md-menu-content>
            </md-menu>
          </md-table-cell>
        </md-table-row>
      </md-table>
      <span v-if="loading" class="b-loading">Loading...</span>
      <pagination
        v-model="properties.mdPage"
        :records="properties.mdCount"
        :per-page="properties.mdPerPage"
        @paginate="updatePagination"
      />
    </md-card>

    <md-dialog :md-active.sync="show">
      <md-dialog-title>Property Details</md-dialog-title>
      <div class="cancel-modal">
        <i class="material-icons" @click="show = false">close</i>
      </div>

      <div class="modal-contain" v-if="property">
        <div class="item">
          <strong>Property title</strong>
          <span> {{ property.title }}</span>
        </div>
        <div class="item">
          <strong>Property Description</strong>
          <span v-html="property.description"> </span>
        </div>
        <div class="item">
          <strong>Property Location</strong>
          <span> {{ property.location }}</span>
        </div>
        <div class="item">
          <strong>State</strong>
          <span> {{ property.state ? property.state.title : "--" }}</span>
        </div>
        <div class="item">
          <strong>Price</strong>
          <span v-if="property.price"> {{ property.price | formatMoney }}</span>
        </div>
        <div class="item">
          <strong>Property type</strong>
          <span v-if="property.type"> {{ property.type.title }}</span>
        </div>
        <div class="item">
          <strong>Date Created</strong>
          <span> {{ property.created_at | formatDate }}</span>
        </div>

        <div class="item">
          <strong>Approval Status</strong>
          <div
            class="badge"
            :class="{
              success: property.approval_status == 'approved',
              danger: property.approval_status == 'disapproved',
            }"
          >
            {{ property.approval_status }}
          </div>
        </div>

        <div class="media" v-if="property.media">
          <div
            class="display"
            v-for="image in property.media.data"
            :key="image"
          >
            <img
              :src="image.media.url"
              onerror="this.src='https://via.placeholder.com/150x150?text=No%20Image'"
              alt="Display Image"
            />
          </div>
        </div>

        <hr />
        <div>
          <h4>User information</h4>
        </div>
        <div v-if="property.user">
          <div class="item">
            <strong>Fullname</strong>
            <span> {{ property.user.name }}</span>
          </div>

          <div class="item">
            <strong>Email Address</strong>
            <span> {{ property.user.email }}</span>
          </div>

          <div class="item">
            <strong>Phone Number</strong>
            <a :href="`tel:${property.user.phone_ext + property.user.phone}`">
              {{ property.user.phone_ext + property.user.phone }}</a
            >
          </div>
        </div>
      </div>
    </md-dialog>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapGetters } = createNamespacedHelpers("realtor");
export default {
  name: "handyman",
  data() {
    return {
      updating: false,
      deleting: false,
      exporting: false,
      search: "",
      show: false,
      time: null,
      property: null,
      showS: false,
      checkAllServices: false,
      approval_status: "",
    };
  },
  watch: {
    search(val) {
      this.waitForIt(val);
    },
  },

  methods: {
    ...mapActions(["getProperties", "updatePropertyStatus"]),
    async updatePagination(page) {
      let data = { page };
      if (this.search) data.keyword = this.search;
      await this.getProperties(data);
    },
    async changeStatus(id, status) {
      try {
        this.updating = true;
        await this.updatePropertyStatus({ approval_status: status, id });
        this.updating = false;
        this.toast("success", "Status Updated ");
      } catch (err) {
        this.updating = false;
        console.error(err);
      }
    },
    async exportExcel() {
      this.exporting = true;
      await this.$store.dispatch("auth/export", { model: "handyman" });
      this.exporting = false;
    },
    async submitSearch() {
      await this.getProperties({ keyword: this.search });
    },
    async promote(item, mode = "create") {
      this.$eventBus.$emit("promote", {
        item_type: this.promotions.REALESTATE,
        item_id: item.id,
        title: item.company_name,
        promote: item.promote,
        mode,
      });
    },
    setProperty(property) {
      this.property = property;
      this.show = true;
    },
    waitForIt(val) {
      if (this.time) {
        clearTimeout(this.time);
      }
      this.time = setTimeout(() => this.submitSearch(val), 1000);
    },
    toggleServices: function () {
      if (this.checkAllServices) {
        this.multi_services = [];
        this.multi_services = this.handyman.services.map(
          (service) => service.pivot.id
        );
      } else {
        this.multi_services = [];
      }
    },
  },
  created() {
    this.$eventBus.$on("promoted", (id) => {
      this.getProperties();
    });
  },
  mounted() {
    this.getProperties();
  },
  computed: {
    ...mapGetters(["properties", "loading", "limit"]),
  },
};
</script>

<style lang="scss" scoped>
.md-table-cell {
  width: 0% !important;
  padding: 0rem 0.8rem;
}

.md-table + .md-table {
  margin-top: 16px;

  thead {
    height: 50px !important;
  }
}

.badge {
  background: #f3f3f3;

  &.success {
    background: green;
  }

  &.danger {
    background: red;
  }
}

.s-con {
  .head,
  .body .s-items {
    display: flex;
    align-items: center;

    > div {
      width: calc(100% / 3);
    }
  }
  .head {
    font-weight: 700;
  }

  .body .s-items .s-item {
    padding: 10px 0px;
  }
}
</style>
